/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */
@import 'intl-tel-input/build/css/intlTelInput.css';
mat-form-field {
  &.mat-mdc-form-field{
    &-fill {
      position: relative;
      bottom: auto;
    }
    &-subscript-wrapper {
      margin-bottom: 20px;
    }
    &.remove-space{
      &-subscript-wrapper {
        margin-bottom: 0px;
      }
    }
    mat-error {
      display: none;
      &:first-child{
        display: block;
      }
    }
    .mat-mdc-input-element{
      &::placeholder{
        opacity: 1;
      }
    }
  }
  &.mat-form-field-hide-placeholder{
    .mat-mdc-select-placeholder {
      color: #95a4b8;
      -webkit-text-fill-color: #95a4b8;
    }
  }
}
.mat-mdc-tab-group{
  .mat-mdc-tab-body-content{
    padding: 24px 0 !important;
  }
}

.iti__country-list{
  max-width: 390px;
  overflow-x: hidden;
}

.tel-field{
  &.mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-form-field-subscript-wrapper {
    z-index: -1 !important;
  }
}